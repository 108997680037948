import axios from "axios"
import { appVersion } from "@/helpers/env"

// Track last update check time in safeLocalStorage to persist between sessions
const LAST_CHECK_KEY = "last_update_check_time"

// Function to check for updates if enough time has passed
async function checkForUpdates(force = false) {
  const now = Date.now()
  const lastCheckTime = parseInt(
    window.safeLocalStorage.getItem(LAST_CHECK_KEY) || "0",
  )

  // Only check once per hour unless forced
  const CHECK_INTERVAL = 60 * 60 * 1000 // 1 hour
  if (!force && now - lastCheckTime < CHECK_INTERVAL) {
    return
  }

  try {
    // Add timestamp to URL to prevent caching
    const timestamp = new Date().getTime()
    const { data } = await axios.get(`/api/v1/app-version?_=${timestamp}`)

    // Update the last check time
    window.safeLocalStorage.setItem(LAST_CHECK_KEY, now.toString())

    if (data.appVersion !== appVersion) {
      window.location.reload()
    }
  } catch (err) {
    console.error("Failed to check for updates:", err)
  }
}

// Check for updates when the page becomes visible
document.addEventListener("visibilitychange", () => {
  if (!document.hidden) {
    checkForUpdates()
  }
})

// Check for updates when the page is shown from back-forward cache
window.addEventListener("pageshow", (event) => {
  if (event.persisted) {
    checkForUpdates(true) // Force check when coming from back-forward cache
  }
})

// Check for updates when the network becomes available
window.addEventListener("online", () => checkForUpdates(true))
